@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Libre+Baskerville:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  cursor: none;
  scrollbar-gutter: stable;
  overflow-x: hidden;
}

.normal {
  --primary-color: #57beac;
  --primary-bright-color: #a4e4de;
  --secundary-color: #fff;
  --subtitle-color: #e1e1e1;
  --dot-bg: black;
  --dot-color: white;
  --dot-size: 1px;
  --dot-space: 22px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(34, 173, 131);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #66b7b0;
  border-radius: 10px;
}

.email-cont {
  position: fixed;
  display: flex;
  gap: 7px;
  z-index: 10;
  top: 0;
  right: -85px;
  transform: rotate(90deg) translateX(50vh);
  color: var(--primary-color);
  font-size: 150%;
  transition: color 0.3s ease-in-out;
}

.email-cont:hover {
  color: var(--primary-bright-color);
  text-decoration: underline;
}

.home-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-family: 'Libre Baskerville', serif;
  color: white;
  background: url("https://i.imgur.com/sS9xq2o.jpg") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 1.9s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 1.9s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 1.9s infinite;
  /* Opera 12+ */
  animation: bg-scrolling-reverse 1.9s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

.home-center-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}

#home-title { 
  font-size: 100px;
}

#home-subtitle {
  margin-top: 10px;
  font-size: 3em;
  color: var(--primary-color);
}

.slice {
  height: 3px;
  width: 60%;
  background-color: white;
}

.slide-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  font-family: 'Lato', sans-serif;
}

.slide-down-ball{
  width: 7px;
  height: 7px;
  background-color: var(--secundary-color);
  border-radius: 50%;
  animation: moveBall 2s ease-in-out infinite;
}

.slide-down p {
  transform: translateX(-50%);
}

.section-title {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 70px;
  margin-top: 0px;
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

/********* PROJECTS CONTENT HOME *********/

.projects-content {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.full-width-line {
  width: 100%;
  height: 3px;
  background-color: black;
}

.m-scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 140px;
  margin: auto;
  overflow: hidden;
  z-index: 1;
}

.m-scroll__title{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(1.5);
  transition: all 1s ease;
  & > div {
    display: flex;
    animation: scrollText 33s infinite linear;
  }
  h3 {
    font-size: 70px;
    color: rgba(255,255,255,1);
    transition: all 2s ease;
    }
    span {
      color:var(--primary-color);
      }
    span:hover{
      -webkit-text-stroke: 1px var(--primary-color);
      color: transparent;
    }
}

/*div:hover {
  animation-play-state: paused;
}*/

.projects-box {
  display: grid;
  margin-top: 70px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 5em;
  justify-content: center;
  align-items: center;
  transition: grid-template-columns 0.5s ease-in-out;
}

.p-box {
  position: relative;
  width: 23vw;
  height: 370px;
}

.p-image-bg {
  width: 23vw; 
  height: 12.94vw;
  background-color: var(--subtitle-color);
  transform: rotate(-4deg) scale(1.04);
  transition: transform 0.2s ease-in-out;
}

.p-image-bg:hover {
  transform: rotate(-5deg) scale(1.05);
}

.p-image {
  position: absolute;
  width: 23vw; 
  height: 12.94vw;
  pointer-events: none;
  z-index: 1;
}

.p-box h3 {
  font-weight: 700;
}

.p-box p {
  font-weight: 400;
  color:#535353;
  margin-top: 3px;
}

.p-info-cont {
  display: flex;
  width: 100%;
}

.p-info-cont-left {
  width: 80%;
}

.p-info-cont-right {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/*****************************************/


/**************** STUDIES CONTENT HOME ******************/

.studies-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181818;
  padding-bottom: 20px;
}

.content {
  color: white;
}

/*************************/


/*********** CONTACT CONTENT HOME ***************/

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181818;
  padding-bottom: 20px;
}

.contact-box {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-box h3 {
  font-size: 45px;
}

/******************************************/

/********* MAIN MENU *********/

.main-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 25px;
}

.menu-selected {
  text-decoration: underline;
  font-weight: 500;
}

.menu-content {
  margin-top: 30px;
  display: flex;
  margin-right: 30px;
  align-items: center;
  justify-content: flex-end;
}

.main-menu ul {
  display: flex;
  text-decoration: none;
  gap: 15px;
  list-style: none;
  margin-right: 30px;
  color: var(--subtitle-color);
  font-size: 20px;
}

.menu-content ul li:hover {
  color: var(--primary-bright-color);
}

.lang-selector {
  display: flex;
  padding: 5px;
  background-color: var(--secundary-color);
  border-radius: 20px;
  width: 75px;
  gap: 5px;
  justify-content: center;
}

.devider {
  height: 20px;
  width: 2px;
  background-color: #636363;
}

.lang-selector button{
  background: none;
  border: none;
  color: #636363;
  cursor: none;
}

.lang-selected {
  color: black !important;
  font-weight: 700;
}


/*****************************/

  /******************** Project Pages ****************/

  .project-page-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40vh;
    justify-content: center;
    align-items: center;
    font-family: 'Libre Baskerville', serif;
    color: white;
    /*background: url("https://i.imgur.com/xiEiL1J.jpg");*/
    background:
      linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
      linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
      var(--dot-color);
    /*transition: background-position 0.5s ease-in-out;*/
  }

  .project-page-title h1 {
    font-size: 60px;
  }

  .project-page-tech {
    display: flex;
    gap: 7px;
  }

  .project-page-tech img {
    width: 30px;
    filter: drop-shadow(0.1em 0.1em 0.2em rgba(255, 255, 255, 0.4));
  }

  .project-page-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .project-page-in {
    display: flex;
    flex-direction: column;
    width: 70%;
    color: black;
    font-size: 1.2em;
  }

  .project-page-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .project-page-more-projects {
    padding: 13px;
    border: 2px var(--primary-color) solid;
    border-radius: 50px;
    color: var(--primary-color);
    font-weight: 700;
    transition: transform 0.1s ease-in-out;
  }

  .project-page-more-projects:hover {
    transform: scale(1.03);
  }

  .projects-menu {
    position: absolute;
    top: 20px;
    left: 30px;
    display: flex;
    gap: 17px;
  }

  .projects-menu svg {
    transition: color 0.1s ease-in-out;
  }

  .projects-menu svg:hover {
    color: var(--primary-color);
  }

  .visit-button {
    margin-top: 30px;
    margin-bottom: -40px;
    background-color: white;
    color: black;
    font-weight: 600;
    padding: 13px;
    border: none;
    border-radius: 20px;
    cursor: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .visit-button:hover {
    background-color: #e0e0e0;
    transform: scale(1.02);
  }

  /***********************************/


  /**************** POPUP ***************/

  .popup-bg {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }

  .close-popup {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .popup-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 400px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .popup-box h3 {
    font-size: 50px;
    font-weight: 600;
  }

  .popup-email-cont {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }

  .popup-email {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30vw;
    height: 40px;
    background-color: var(--subtitle-color);
    border-radius: 20px;
    padding: 13px;
    font-weight: 600;
    border: 1px transparent solid;
    transition: border 0.2s ease-in-out;
  }

  .popup-email:hover {
    border: 1px var(--primary-color) solid;
  }

  .popup-email-cont svg {
    transition: color 0.2s ease-in-out;
  }

  .popup-email-cont svg:hover {
    color: var(--primary-color);
  }

  /******************************/

.mt-90 {
  margin-top: -90px;
}

.t70 {
  margin-top: 70px;
}

.t100 {
  margin-top: 100px;
}

.t40 {
  margin-top: 40px;
}

.t7 {
  margin-top: 7px;
}

.b {
  font-weight: 700;
}

.white {
  color: white;
}

.round {
  border-radius: 10px;
}

.primary {
  color: var(--primary-color);
}

.cursor {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  pointer-events: none;
}

.main-cursor {
  background-color: var(--primary-color);
  z-index: 9;
}

.following-cursor {
  background-color: var(--primary-bright-color); 
  opacity: 0.5;
  transition: transform 0.2s ease-out; 
  z-index: 8;
}

.following-cursor-large {
  background-color: var(--primary-bright-color);
  opacity: 0.3;
  width: 50px; 
  height: 50px; 
  border-radius: 50%;
  z-index: 10;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  margin-top: -20px;
  margin-left: -20px;
}

::selection {
  background-color: var(--primary-color);
}

@keyframes moveBall {
  0%, 100% {
    transform: translateX(-50%) translateY(-15px) scale(0.8);
  }
  20% {
    transform: translateX(-50%) translateY(0px);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollText {
  from   { transform: translateX(0%); }
  to { transform: translateX(-50%); }
}

@media only screen and (min-width: 2000px) {
  .home-center-text {
    max-width: 1100px;
  }
  .projects-box {
    display: grid;
    margin-top: 70px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    gap: 5em;
    justify-content: center;
    align-items: center;
    transition: grid-template-columns 0.5s ease-in-out;
  }

  .p-image-bg {
    width: 12vw; 
    height: calc(12vw * 9 / 16);
    background-color: var(--subtitle-color);
    transform: rotate(-4deg) scale(1.04);
    transition: all 0.7 ease-in;
    z-index: 2;
  }

  .p-box {
    width: 12vw;
  }

  .p-image {
    position: absolute;
    width: 12vw; 
    height: calc(12vw * 9 / 16);
    background-color: aquamarine;
    pointer-events: none;
    z-index: 1;
  }
}

@media only screen and (max-width: 1200px) {
  body {
    cursor: auto;
  }

  #home-title {
    font-size: 55px;
  }

  .slice {
    width: 45vw;
    height: 1px;
  }

  .mt-90 {
    margin-top: -50px;
  }

  #home-subtitle {
    font-size: 250%;
  }

  .projects-box {
    grid-template-columns: repeat(2, 1fr);
    transition: grid-template-columns 0.5s ease-in-out;
  }

  .p-image-bg {
    width: 35vw; 
    height: calc(35vw * 9 / 16);
    background-color: var(--subtitle-color);
    transform: rotate(-4deg) scale(1.04);
    transition: all 0.7 ease-in;
    z-index: 2;
  }

  .p-box {
    width: 35vw;
  }

  .p-image {
    position: absolute;
    width: 35vw; 
    height: calc(35vw * 9 / 16);
    background-color: aquamarine;
    pointer-events: none;
    z-index: 1;
  }

  .cursor {
    display: none;
  }
  
  .main-cursor {
    display: none;
  }
  
  .following-cursor {
    display: none;
  }
  
  .following-cursor-large {
    display: none;
  }

  .tagcloud {
    width: 500px !important;
    height: 500px !important;
  }

  .studies-content {
    padding: 30px;
    box-sizing: border-box;
  }

  .contact-content {
    padding: 15px;
    box-sizing: border-box;
    text-align: center;
  }

  .project-page-in {
    width: 85%;
  }

}

@media only screen and (max-width: 700px) {
  body {
    cursor: auto;
    overflow-x: hidden;
  }

  .email-cont {
    display: none;
  }

  #home-title {
    margin-top: -20px;
    font-size: 35px;
  }

  .slice {
    width: 45vw;
    height: 1px;
  }

  .mt-90 {
    margin-top: -10px;
  }

  #home-subtitle {
    font-size: 150%;
  }

  .projects-box {
    grid-template-columns: repeat(1, 1fr);
    gap: 5em;
  }

  .p-box {
    width: 70vw;
    height: auto;
  }

  .p-image-bg {
    width: 70vw; 
    height: calc(70vw * 9 / 16);
    background-color: var(--subtitle-color);
    transform: rotate(-4deg) scale(1.04);
    transition: all 0.7 ease-in;
    z-index: 2;
  }

  
  .p-image {
    position: absolute;
    width: 70vw; 
    height: calc(70vw * 9 / 16);
    pointer-events: none;
    z-index: 1;
  }

  .cursor {
    display: none;
  }
  
  .main-cursor {
    display: none;
  }
  
  .following-cursor {
    display: none;
  }
  
  .following-cursor-large {
    display: none;
  }

  .studies-content {
    padding: 17px;
    box-sizing: border-box;
  }

  .contact-content {
    padding: 15px;
    box-sizing: border-box;
    text-align: center;
  }

  .popup-email {
    width: 90% !important;
  }
  .content {
    display: none;
  }

  .tagcloud {
    width: 300px !important;
    height: 300px !important;
  }

  .project-page-top {
   padding: 13px;
   box-sizing: border-box;
  }

  .project-page-title h1 {
    font-size: 30px;
    text-align: center;
  }

  .project-page-in {
    width: 90%;
  }
}

@media (max-width: 400px) {
   #home-title {
    font-size: 30px;
   }
  
}


  #noise-grain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.032;
    pointer-events: none;
    background: url("https://i.imgur.com/KcCJRkr.gif");
    z-index: 99;
  }

  @-webkit-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-moz-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-o-keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @keyframes bg-scrolling-reverse {
    100% {
      background-position: 50px 50px;
    }
  }
  @-webkit-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @-moz-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @-o-keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }
  @keyframes bg-scrolling {
    0% {
      background-position: 50px 50px;
    }
  }